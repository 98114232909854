import React from 'react';
import { withTheme } from 'styled-components';
import { Box, Text } from 'rebass';

import { Hide } from '../hide';

export const WizardStep = withTheme(props => {
  return (
    <Hide mobile tablet>
      <Box
        {...props}
        pt="20px"
        pl={props.active ? '40px' : '20px'}
        pr="20px"
        bg={props.active ? 'maize' : 'blues.peacock'}
        css={{
          position: 'relative',
          height: '80px',
          left: `${props.active ? '' : '15px'}`,
          '&:before': {
            content: `${props.active ? '""' : ''}`,
            position: 'absolute',
            left: '-1px',
            bottom: 0,
            width: 0,
            height: 0,
            borderLeft: `${props.active ? '15px solid #01549b' : 'none'}`,
            borderTop: `${props.active ? '40px solid transparent' : 'none'}`,
            borderBottom: `${props.active ? '40px solid transparent' : 'none'}`,
          },
          '&:after': {
            content: `${props.active ? '""' : ''}`,
            position: 'absolute',
            right: '-14px',
            bottom: 0,
            width: 0,
            height: 0,
            borderLeft: '15px solid #f4d448',
            borderTop: '40px solid transparent',
            borderBottom: '40px solid transparent',
          },
        }}
      >
        <Text fontSize="12px" color={props.active ? 'blues.peacock' : 'white'}>
          {props.subtitle}
        </Text>
        <Text
          fontSize="16px"
          fontWeight="bold"
          color={props.active ? 'blues.peacock' : 'white'}
        >
          {props.title}
        </Text>{' '}
      </Box>
    </Hide>
  );
});
