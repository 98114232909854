import React, { useEffect, useRef } from 'react';
import bwipjs from 'bwip-js';

export default function Barcode({
  value,
  width = '230px',
  height = 10,
  bg = '#ecedf0',
  ...rest
}) {
  const canvasRef = useRef();
  useEffect(() => {
    bwipjs(
      canvasRef.current,
      {
        bcid: 'code128', // Barcode type
        text: value, // Text to encode
        scale: 2, // 3x scaling factor
        height: 10, // Bar height, in millimeters
        includetext: false, // Show human-readable text
        textxalign: 'center', // Always good to set this
      },
      function(err, cvs) {
        if (err) {
          // Decide how to handle the error
          // `err` may be a string or Error object
          console.log(err);
        } else {
          // Nothing else to do in this example...
        }
      }
    );
  });

  return (
    <>
      <canvas ref={canvasRef} style={{ width }} />
      {/* <ReactBarcode
        value={value}
        background={bg}
        lineColor="#032e4f"
        displayValue={false}
        width={1.65}
        height={60}
        margin={0}
        {...rest}
      /> */}
    </>
  );
}
