import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import { Flex, Box, Card, Heading, Image, Text, Button } from 'rebass';
import Modal, { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import { useTranslation } from 'react-i18next';
import ScaleLoader from "react-spinners/ScaleLoader";

import useForm, { FormContext, useFormContext } from 'react-hook-form';

import Form from '../Form';
import { WizardStep } from './WizardModalAddon';

import CloseCrossImg from './images/close@2x.png';
import CombinedShapeIcn from '../../icons/combined-shape.svg';
import RightArrowIcn from '../../icons/right-arrow.svg';
import LeftArrowIcn from '../../icons/left-arrow.svg';
import vhCheck from 'vh-check';


const SpecialModalBackground = styled(BaseModalBackground)`
  background-color: rgba(255, 255, 255, 0.8);
`;

const StyledModal = Modal.styled`
  ${space}
  ${layout}  
  background-color: ${props => props.theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
`;

const ModalHeader = ({
  title,
  subtitle,
  wizardSteps,
  hasNext,
  hasFinish,
  activeStep,
  onCloseButtonClick,
  onPreviousButtonClick,
  onFinishButtonClick,
  onNextButtonClick,
  disableSubmitButton,
  ...props
}) => {
  const {getValues, triggerValidation, reset, clearError} = useFormContext()

  return (
  <>
    <Box
      {...props}
      width="100%"
      backgroundColor="blues.peacock"
      css={{
        borderRadius: '6px 6px 0 0',
      }}
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        css={{ height: '80px' }}
      >
        <Box 
          pl={"18px"}
          mr="24px">
          <Text fontSize="17px" letterSpacing="-0.24px" color="white">
            {subtitle}
          </Text>
          <Heading
            pt="3px"
            fontSize={['14px', '27px', '27px']}
            fontWeight="900"
            lineHeight="0.93"
            color="white"
          >
            {title}
          </Heading>
        </Box>
        {wizardSteps &&
          wizardSteps.map((step, idx) => (
            <WizardStep
              key={`step_${idx}`}
              active={activeStep === idx + 1}
              title={step.title}
              subtitle={step.subtitle}
            />
          ))}
        <Box mx="auto" />
        <Box
          pr={"18px"}
          pt={"10px"}
        >
           <Image
            width="26px"
            height="26px"
            src={CloseCrossImg}
            onClick={onCloseButtonClick}
            css={{
              ':hover': {
                cursor: 'pointer',
              },
            }} />
        </Box>
      </Flex>
    </Box>
    <Box
      m="0"
      py={['5px']}
      width={1}
      bg="maize"
      display={['none', 'block', 'block']}
      css={{ height: '0' }}
    />
    {wizardSteps && (
      <Box
        m="0"
        py={['20px']}
        width={1}
        bg="maize"
        display={['block', 'none', 'none']}
        textAlign="center"
    > { disableSubmitButton && 
      <Box textAlign="center" width={['100%']}>     
        <ScaleLoader
          css={{}}
          size={20}
          color={"#01549b"}
          loading={true}
          />
      </Box>
      }
      { !disableSubmitButton &&
        <Flex width={1} flexDirection="row" px="20px">
          <Image src={LeftArrowIcn} onClick={()=> { 
              clearError();
              reset(); 
              onPreviousButtonClick() }} />
          <Box flexGrow={2}>
            <Text fontSize="12px" color="blues.peacock">
              {wizardSteps[activeStep - 1].subtitle}
            </Text>
            <Heading fontSize="16px" color="blues.peacock">
              {wizardSteps[activeStep - 1].title}
            </Heading>
          </Box>
          {(hasNext || hasFinish) && (
            <>
            { hasNext ? (
              <Image 
                type="image" 
                src={RightArrowIcn}
                onClick={async () => {
                    if(activeStep !== 3){
                      const values = await getValues()
                      onNextButtonClick(values)
                      
                    }else{
                    const result = await triggerValidation();
                      if (result) {
                        const values = await getValues()
                        onNextButtonClick(values) 
                      }
                    }
                  }}
              />): (<Image 
                    as="input" 
                    type="image"
                    src={RightArrowIcn} 
                    onClick={async () => {
                      onFinishButtonClick()
                    }}
                    />)
            }
            </>)}
        </Flex>
        }
      </Box>
    )}
  </>
)
};

export const ModalFooterButton = ({
  display = true,
  secondary = false,
  large = false,
  disabled = false,
  ...props
}) => {
  const bgColor = disabled ? "gray" : secondary ? '' : 'blues.peacock'
  if (!display) return null;
  return (
    <Button
      disabled={disabled}
      {...props}
      fontSize="14px"
      fontWeight="bold"
      color={secondary ? 'blues.peacock' : 'white'}
      bg={bgColor}
      css={{
        width: `${large ? '203px' : '140px'}`,
        height: '38px',
        letterSpacing: '2.34px',
        textAlign: 'center',
        border: '1px solid',
        borderColor: `${secondary ? 'blues.peacock' : ''}`,
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    />
  );
};

const ModalFooter = ({
  hasPrevious,
  hasNext,
  hasFinish,
  onPreviousButtonClick,
  onNextButtonClick,
  onCloseButtonClick,
  disableSubmitButton,
  onFinishButtonClick,
  activeStep
}) => {
  const { t } = useTranslation();

  const {getValues, triggerValidation, reset, clearError} = useFormContext()
  console.log('footer activeStep:', activeStep)
  console.log('footer hasFinish:', hasFinish)
  console.log('footer hasNext:', hasNext)
  console.log('footer disableSubmitButton:', disableSubmitButton)
  return (
    <Flex mx={['20px', '50px', '50px']} pt="30px" pb="36px" alignItems="center">
       {!disableSubmitButton ? (
         <>
          <ModalFooterButton
            mx="auto"
            display={!hasNext && !hasPrevious}
            type="button"
            onClick={onCloseButtonClick}
          >
            {t('modal.close')}
          </ModalFooterButton>

          <ModalFooterButton
            display={hasPrevious}
            large
            secondary
            type="button"
            onClick={()=> { 
              clearError();
              reset(); 
              onPreviousButtonClick() }}
          >
            {t('modal.previous')}
          </ModalFooterButton>

          {(hasNext || hasFinish) && (
            <>
              <Box mx="auto" />
              { hasNext? (
                <ModalFooterButton
                  large
                  type="button"
                  onClick={async () => {
                    if(activeStep !== 3){
                      onNextButtonClick(getValues())
                      
                    }else{
                    const result = await triggerValidation();
                      if (result) {
                        onNextButtonClick(getValues())
                     }
                    }
                  }}
                >
                  {t('modal.next')}
                </ModalFooterButton>):
                (
                  <ModalFooterButton
                  large
                  type="button"
                  onClick={async () => {
                    onFinishButtonClick()
                  }}
                >
                {t('modal.finish')}
              </ModalFooterButton>)
              }
            </>
          )}
        </>
      ):   
       <Box textAlign="center" width={['100%']}>     
        <ScaleLoader
          css={{}}
          size={40}
          color={"#01549b"}
          loading={true}
          />
      </Box>
    }
    </Flex>
  );
};

const BoxitModal = ({
  title,
  subtitle,
  onCloseButtonClick,
  wizardSteps = null,
  activeStep,
  onPreviousButtonClick,
  onNextButtonClick,
  onFinishButtonClick,
  onSubmitButtonClick,
  footer,
  children,
  disableSubmitButton,
  ...rest
}) => (<StyledModal {...rest}>
    <Flex flexWrap="wrap" flexDirection="column">
      <Box
        width="100%"
      >
      <Form
        onSubmit={
            !wizardSteps
              ? onSubmitButtonClick:
                ()=>{}
          }
      >
        <ModalHeader
          title={title}
          subtitle={subtitle}
          activeStep={activeStep}
          wizardSteps={wizardSteps}
          hasPrevious={wizardSteps && activeStep > 1}
          hasNext={wizardSteps && wizardSteps.length > activeStep}
          hasFinish={wizardSteps && wizardSteps.length === activeStep}
          onCloseButtonClick={onCloseButtonClick}
          onPreviousButtonClick={onPreviousButtonClick}
          onNextButtonClick={onNextButtonClick}
          onFinishButtonClick={onFinishButtonClick}
          disableSubmitButton={disableSubmitButton}
        />

        {/* Modal Body */}
        <Card
          backgroundImage={'url(' + CombinedShapeIcn + ')'}
          backgroundSize="auto"
          backgroundRepeat="no-repeat"
          backgroundPosition={[
            'bottom 10% right 120%',
            'bottom 60% right -17%',
            'bottom 60% right -17%',
          ]}
          maxHeight={['calc(100vh - 185px - var(--vh-offset, 0px))', 'auto', 'auto']}
          px={['19px', '30px', '84px']}
          py={'29px'}
          bg="white"
          css={{
            // backgroundAttachment: 'fixed',
            'overflow-y': 'scroll',
          }}
        >
          {children}
        </Card>
        {footer ? (
          footer()
        ) : (
          <Box display={['none', 'block', 'block']}>
            <ModalFooter
              activeStep={activeStep}
              hasPrevious={wizardSteps && activeStep > 1}
              hasNext={wizardSteps && wizardSteps.length > activeStep}
              hasFinish={wizardSteps && wizardSteps.length === activeStep}
              onPreviousButtonClick={onPreviousButtonClick}
              onCloseButtonClick={onCloseButtonClick}
              onNextButtonClick={onNextButtonClick}
              onFinishButtonClick={onFinishButtonClick}
              disableSubmitButton={disableSubmitButton}
            />
          </Box>
        )}
      </Form>
      </Box>
    </Flex>
  </StyledModal>
);

export class BoxitModalProvider extends React.Component {
  componentDidMount() {
    vhCheck();
  }
  render() {
    return (
      <ModalProvider backgroundComponent={SpecialModalBackground}>
        {this.props.children}
      </ModalProvider>
    );
  }
}
// export const BoxitModalProvider = ({ children }) => {
//   return (
//     <ModalProvider backgroundComponent={SpecialModalBackground}>
//       {children}
//     </ModalProvider>
//   );
// };

export default BoxitModal;
