import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

import { Hide } from '../hide';
import Barcode from '../Barcode';


import { datetime } from '../../utils';

const ShippingDetails = ({
  boxId,
  recipient: {
    fullname,
    address: { street, city, zipCode },
  },
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="row" flexWrap="wrap">
      <Box width={[1, 1 / 2, 1 / 2]} mb="25px">
        <Flex flexDirection="column">
          <Heading
            pb="15px"
            fontSize="15px"
            fontWeight="normal"
            lineHeight="1.37"
            letterSpacing="0.7px"
            color="blues.peacock"
          >
            {t('box_status_info.shipping_details')}
          </Heading>
          <Text
            fontSize="15px"
            lineHeight="1.84"
            lineSpacing="0.70px"
            color="marine"
          >
            {fullname}
            <br />
            {street}, {city}
            <br />
            {zipCode}
          </Text>
        </Flex>
      </Box>
      <Box mx="auto" />
      <Box>
        <Heading
          pb="20px"
          fontSize="15px"
          fontWeight="normal"
          lineHeight="1.37"
          letterSpacing="0.7px"
          color="blues.peacock"
          textAlign={['left', 'right', 'right']}
        >
          {t('box_status_info.tracking_barcode')}
        </Heading>
        {boxId && (
          <>
            <Hide mobile tablet>
              <Barcode value={boxId} width="340px" height={80} bg="#ffffff" />
            </Hide>
            <Hide desktop>
              <Barcode value={boxId} width="320px" height={80} bg="#ffffff" />
            </Hide>
          </>
        )}
      </Box>
    </Flex>
  );
};

const DeliveryPoint = ({
  deliveryPoint: {
    name,
    contact: { address, phone },
  },
}) => {
  const { t } = useTranslation();

  return (
    <Box width={[1, 1 / 4, 1 / 4]}>
      <Flex flexDirection="column">
        <Heading
          pb="15px"
          fontSize="15px"
          fontWeight="normal"
          lineHeight="1.37"
          letterSpacing="0.7px"
          color="blues.peacock"
        >
          {t('box_status_info.delivery_point')}
        </Heading>
        <Text
          fontSize="14px"
          lineHeight="1.84"
          letterSpacing="0.70px"
          color="marine"
        >
          {name}
        </Text>
        <Text fontSize="12px" lineHeight="1.47" color="marine">
          {address.street}, {address.city}
          <br />
          {phone}
        </Text>
      </Flex>
    </Box>
  );
};

const DeliveryAtHome = ()=>{
  const { t } = useTranslation();
  return <Box width={[1, 1 / 4, 1 / 4]}>
  <Flex flexDirection="column">
    <Heading
      pt={['25px', '0', '0']}
      pb="15px"
      fontSize="15px"
      fontWeight="normal"
      lineHeight="1.37"
      letterSpacing="0.7px"
      color="blues.peacock" 
      textAlign="right"
    >
       {t('box_status_info.pickup_point')}
    </Heading>
    <Text
          fontSize="14px"
          lineHeight="1.84"
          letterSpacing="0.70px"
          color="marine"
          textAlign="right"
        >
    {t('box_status_info.deliveryAtHome')}
        </Text>
    </Flex>
    </Box>
}

const PickupPoint = ({
  pickupPoint: {
    name,
    contact: { address, phone },
  },
}) => {
  const { t } = useTranslation();

  return (
    <Box width={[1, 1 / 4, 1 / 4]}>
      <Flex flexDirection="column">
        <Heading
          pt={['25px', '0', '0']}
          pb="15px"
          fontSize="15px"
          fontWeight="normal"
          lineHeight="1.37"
          letterSpacing="0.7px"
          color="blues.peacock"
          textAlign="right"
        >
          {t('box_status_info.pickup_point')}
        </Heading>
        <Text
          fontSize="14px"
          lineHeight="1.84"
          letterSpacing="0.70px"
          color="marine"
          textAlign="right"
        >
          {name}
        </Text>
        <Text
          fontSize="12px"
          lineHeight="1.47"
          color="marine"
          textAlign="right"
        >
          {address.street}, {address.city}
          <br />
          {phone}
        </Text>
      </Flex>
    </Box>
  );
};

const TrackingHistoryRow = ({ date, location, status }) => {
  const { t } = useTranslation();

  return (
    <Box
      css={{
        borderTop: '1px solid rgba(1, 84, 155, 0.25)',
      }}
    >
      <Flex
        width={1}
        flexWrap="wrap"
        alignItems="center"
        css={{
          minHeight: '80px',
        }}
      >
        <Box width={1 / 5}>
          <Text
            fontSize={['12px', '16px', '16px']}
            fontWeight="bold"
            lineHeight="1"
            letterSpacing="0.3px"
            p="10px"
            color="blues.twilight"
          >
            {date}
          </Text>
        </Box>
        <Box width={2 / 5}>
          <Text
            p="10px"
            fontSize={['12px', '16px', '16px']}
            fontWeight="bold"
            letterSpacing="0.3px"
            textAlign="right"
            color="blues.twilight"
          >
            {t(`box_status_info.${status}`)}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

const TrackingHistory = ({trackingHistory}) => {
  const { t } = useTranslation();

  return (
    <Flex width={1} flexDirection="column">
      {/* Header */}
      <Box
        mt="22px"
        css={{
          borderTop: '1px solid rgba(1, 84, 155, 0.25)',
        }}
      >
        <Flex width={1} flexWrap="wrap">
          <Box width={1 / 5}>
            <Text fontSize="15px" p="10px" color="blues.peacock">
              {t('box_status_info.date')}
            </Text>
          </Box>
          <Box width={2 / 5}>
            <Text
              fontSize="15px"
              p="10px"
              textAlign="right"
              color="blues.peacock"
            >
              {t('box_status_info.status')}
            </Text>
          </Box>
        </Flex>
      </Box>
      { trackingHistory.map((tracking)=>
        <TrackingHistoryRow 
          date={datetime(tracking._ts)} 
          status={tracking.status} 
        />)
      }
    </Flex>
  );
};

const Steps = ({step}) => (
  <Box width={[1, 2 / 4, 2 / 4]} alignSelf="center">
    <Hide mobile>
      <Flex alignItems="center">
        {/* Step 1 */}
        <Box
          width="40px"
          bg="blues.veryLight"
          css={{ height: '40px', borderRadius: '50%' }}
        >
          {step >= 1 && <Box
            mt="7px"
            mx="auto"
            width="26px"
            bg="blues.peacock"
            css={{ height: '26px', borderRadius: '50%' }}
          />
          }
        </Box>
        <Box
          mx="-2px"
          width="170px"
          bg="blues.veryLight"
          css={{
            height: '14px',
          }}
        >
          {step >= 2 &&
            <Box
              mt="4px"
              mx="-5px"
              width="170px"
              bg="blues.peacock"
              css={{
                height: '5px',
              }}
            ></Box>
          }
        </Box>
        {/* Step 2 */}
        <Box
          width="40px"
          bg="blues.veryLight"
          css={{ height: '40px', borderRadius: '50%' }}
        >
          {step >= 2 &&
          <Box
            mt="7px"
            mx="auto"
            width="26px"
            bg="blues.peacock"
            css={{ height: '26px', borderRadius: '50%' }}
          ></Box>
          }
        </Box>
        <Box
          mx="-2px"
          width="170px"
          bg="blues.veryLight"
          css={{
            height: '14px',
          }}
        >
        {step > 2 &&
            <Box
              mt="4px"
              mx="-5px"
              width="170px"
              bg="blues.peacock"
              css={{
                height: '5px',
              }}
            ></Box>
          }
        </Box>
        {/* Step 3 */}
        <Box
          width="40px"
          bg="blues.veryLight"
          css={{ height: '40px', borderRadius: '50%' }}
        >
          {step > 2 && <Box
                      mt="7px"
                      mx="auto"
                      width="26px"
                      bg="blues.peacock"
                      css={{ height: '26px', borderRadius: '50%' }}
                    />
          }
        </Box>
      </Flex>
    </Hide>
  </Box>
);

const BOX_STATUSES_STEPS = {
  pending: 0,
  paid: 0,
  scanned: 1, 
  shipped: 2,
  dropped: 2,
  inTransit: 2,
  delivered: 3,
  not_delivered: 3
}

const TrackingInfo = ({ deliveryPoint, pickupPoint, trackingHistory, boxStatus, deliveryAtHome }) => (
  <Box
    mt="10px"
    pt="13px"
    css={{
      borderTop: '1px solid rgba(1, 84, 155, 0.25)',
    }}
  >
    <Flex
      alignItems="baseline"
      justifyContent="space-around"
      flexDirection="row"
      flexWrap="wrap"
    >
      <DeliveryPoint deliveryPoint={deliveryPoint} />
      <Steps step={boxStatus} />
      {/* <Box mx="auto" /> */}
      {deliveryAtHome ?  <DeliveryAtHome /> :
      <PickupPoint pickupPoint={pickupPoint} />
      }
    </Flex>
    {( trackingHistory && trackingHistory.length !== 0) && (
      <Flex>
        <TrackingHistory trackingHistory={trackingHistory}/>
      </Flex>
    )}
  </Box>
);

export default function BoxStatusInfo({ boxData }) {
  return (
    <>
      <ShippingDetails boxId={boxData._id} recipient={boxData.recipient} />
      <TrackingInfo
        boxStatus={BOX_STATUSES_STEPS[boxData.status]}
        deliveryPoint={boxData.deliveryPoint}
        pickupPoint={boxData.pickupPoint}
        trackingHistory={boxData.history}
        deliveryAtHome={boxData.deliveryAtHome}
      />
    </>
  );
}
