import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Card, Text, Button } from 'rebass';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Header } from '../components/Header';
import BoxitModal, { BoxitModalProvider } from '../components/Modal';
import { EasySteps } from '../components/EasySteps';
import { swiperParams } from '../theme';
import BoxStatusInfo from '../components/BoxStatusInfo';
import Alert from '../components/Alert';

import HeroBackgroundImageXL from '../images/hero-image-4@3x.jpg';

import { datetime } from '../utils';

const StyledButton = styled(Button)`
  cursor: pointer;
  width: 240px;
  height: 40px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.blues.peacock};
`;

const BOX_TRACK_DETAILS = gql`
  query TrackBox($boxId: ID!) {
    findBoxByID(id: $boxId) {
      _id
      _ts
      status
      history {
          status
          _ts
          _id
      }
      recipient {
        fullname
        address {
          street
          zipCode
          city
        }
        email
        phone
      }
      deliveryPoint {
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
      deliveryAtHome
      pickupPoint {
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
    }
  }
`;

function TrackYourBoxModalButton({ boxId, onError, auto }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingAutoQuery, setPendingAutoQuery] = useState(false);
  const [trackBox, { loading, data }] = useLazyQuery(BOX_TRACK_DETAILS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.findBoxByID) {
        setModalOpen(true);
      } else {
        onError(t('box_tracking.boxid_not_found'));
      }
    },
  });

  const { t } = useTranslation();

  const handleClick = () => {
    trackBox({ variables: { boxId } });
  };

  if(boxId && boxId !== "" && !pendingAutoQuery && auto){
    setPendingAutoQuery(true)
    trackBox({ variables: { boxId } });
  }

  return (
    <>
      <Flex mx="auto">
        <StyledButton onClick={handleClick}>
          {t('box_tracking.button')}
        </StyledButton>
      </Flex>

      {data && data.findBoxByID && (
        <BoxitModal
          title={`BOX ID: ${data.findBoxByID._id}`}
          subtitle={datetime(data.findBoxByID.createdAt)}
          isOpen={modalOpen}
          onBackgroundClick={() => setModalOpen(false)}
          onEscapeKeydown={() => setModalOpen(false)}
          onCloseButtonClick={() => setModalOpen(false)}
        >
          <BoxStatusInfo boxData={data.findBoxByID} />
        </BoxitModal>
      )}
    </>
  );
}

const TrackingBoxInput = styled.input`
  width: 100%;
  font-size: 15px;
  padding: 11px 18px 6px 18px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightBlueGrey};
  background-color: #ffffff;
  color: ${props => props.theme.colors.marine};
  line-height: 1.37;
  letter-spacing: 0.7px;
  &::placeholder {
    color: ${props => props.theme.colors.lighGreyBlue};
  }
`;

export default function BoxTrackingPage(props) {
  const [boxId, setBoxId] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);

  const handleError = msg => {
    setAlertMsg(msg);
  };

  const { t } = useTranslation();

  const query = props.location.search ? queryString.parse(props.location.search): {}
  if(query.boxId && !boxId){
    setBoxId(query.boxId);
  }

  return (
    <Layout {...props}>
      <BoxitModalProvider>
        <SEO title="WEBOXIT TRACKING" keywords={['weboxit', 'boxit']} />
        <Header
          title={t('box_tracking.title')}
          subtitle={t('box_tracking.subtitle')}
        />
        <Card
          pt={['0', '30px', '145px']}
          backgroundImage={`url(${HeroBackgroundImageXL})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition={['top center', 'top center']}
          css={{ minHeight: '280px', maxWidth: '1440px' }}
          mx="auto"
        >
          <Flex>
            <Card
              width={['100%', '70%', '70%']}
              borderRadius={['0', '20px', '20px']}
              bg="blues.veryLightWithOpacity"
              mx="auto"
              my={['46px', '176px', '176px']}
              px="48px"
              py="26px"
            >
              <Alert message={alertMsg} />
              <Text
                fontSize="15px"
                lineHeight="1.84"
                letterSpacing="0.7px"
                color="marine"
              >
                {t('box_tracking.track_your_box')}
              </Text>
              <TrackingBoxInput
                defaultValue={query.boxId ? query.boxId : ''}
                placeholder={t('box_tracking.placeholder')}
                onChange={e => setBoxId(e.target.value)}
              />
              <Flex pt="18px" flexDirection="column">
                <TrackYourBoxModalButton boxId={boxId} onError={handleError} auto={query.auto} />
              </Flex>
            </Card>
          </Flex>
        </Card>
        <EasySteps params={{ swiperParams }} />
      </BoxitModalProvider>
    </Layout>
  );
}
