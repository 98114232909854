import React from 'react';
import Swiper from 'react-id-swiper';
import { Flex, Box, Text, Image, Card } from 'rebass';
import { useTranslation } from 'react-i18next';

import { Container } from '../container';
import { Hide } from '../hide';

import BgImg from './icons/easy-steps-triangle.svg';
import CharacterStep1 from './icons/character-1.svg';
import CharacterStep2 from './icons/character-3.svg';
import CharacterStep3 from './icons/character-2.svg';
import CharacterStep4 from './icons/character-4.svg';

var Characters = [
  CharacterStep1,
  CharacterStep2,
  CharacterStep3,
  CharacterStep4,
];

export default function EasySteps({
  params,
  customStyle = '',
  showHeader = true,
}) {
  const { t } = useTranslation();

  var Actions = t('why_we_boxit.4_steps.easy_steps.actions', {
    returnObjects: true,
  });

  return (
    <>
      <Card
        backgroundImage={'url(' + BgImg + ')'}
        alt="Background Image"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom center"
        hidden={!showHeader}
        css={{ maxWidth: '1440px' }}
        mx="auto"
      >
        <Container>
          <Text
            pt={['10px', customStyle === 's1' ? '30px' : '110px', 6]}
            color="white"
            fontSize={['22px', '29px', '35px']}
            lineHeight="1.03"
            textAlign="center"
          >
            {t('why_we_boxit.4_steps.easy_steps.title')}
          </Text>
          <Text
            pt={[2, 2, 3]}
            pb={['60px', '20px', '52px']}
            color="maize"
            fontSize={['22px', '29px', '35px']}
            lineHeight="1.03"
            textAlign="center"
          >
            {t('why_we_boxit.4_steps.easy_steps.subtitle')}
          </Text>
        </Container>
      </Card>
      <Hide tablet desktop>
        <Container>
          <Swiper {...params.swiperParams}>
            {Actions &&
              Actions.map((action, index) => (
                <div key={'easyStep' + (index + 1)}>
                  <Image
                    key={'easyStepsImage' + index}
                    mx="auto"
                    px="40px"
                    pt="45px"
                    pb={4}
                    width={1}
                    src={Characters[index]}
                    alt={'Easy Step ' + index}
                    backgroundSize="cover"
                  />
                  <Box
                    mx="auto"
                    mb="45px"
                    bg="blues.peacock"
                    css={{ height: '76px', borderRadius: 20 }}
                  >
                    <Flex key={'easyStepsDescriptions' + index}>
                      <Text
                        key="easyStepsDescriptionsIndex"
                        m="0"
                        p={4}
                        color="white"
                        fontSize="54px"
                        fontWeight="bold"
                        lineHeight="1"
                        letterSpacing="-0.74px"
                      >
                        {index + 1}
                      </Text>
                      <Flex flexDirection="column" flexWrap="wrap">
                        <Text
                          key="easyStepsDescriptionsTitle"
                          pt="12px"
                          color="maize"
                          fontSize="15px"
                          fontWeight="bold"
                          letterSpacing="0.94px"
                          textAlign="left"
                        >
                          {action.title}
                        </Text>
                        <Text
                          key={'easyStepsDescriptionsSubtitle' + index}
                          color="white"
                          pt="4px"
                          pr="20px"
                          fontSize="12px"
                          fontWeight="bold"
                          lineHeight="1.22"
                          textAlign="left"
                        >
                          {action.subtitle}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </div>
              ))}
          </Swiper>
        </Container>
      </Hide>

      <Hide mobile>
        <Container>
          <Flex
            pt={[0, 5, '96px']}
            pb={[0, 0, 3]}
            flexWrap="nowrap"
            flexDirection="row"
          >
            {Actions &&
              Actions.map((action, index) => (
                <Image
                  key={'easyStepsImage' + index}
                  px={[0, 3, 3]}
                  py={[0, 3, 0]}
                  mb={[0, 0, 4]}
                  width={[1 / Actions.length]}
                  src={Characters[index]}
                  alt={'Easy Steps' + index}
                  backgroundSize="cover"
                  css={{ height: 'fit-content' }}
                />
              ))}
          </Flex>
          <Box
            mx="auto"
            bg="blues.peacock"
            css={{ borderRadius: 20 }}
          >
            <Flex
              width={1}
              flexWrap="nowrap"
              flexDirection="row"
              css={{ overflow: 'hidden' }}
            >
              {Actions &&
                Actions.map((action, index) => (
                  <Flex
                    key={'easyStepsDescriptions' + index}
                    alignItems="top center"
                    width={[1, 1 / Actions.length]}
                    pl={[0, 3, 0]}
                    css={{ flex: '0 0 auto', overflow: 'hidden'  }}
                  >
                    <Text
                      key="easyStepsDescriptionsIndex"
                      m="0"
                      pl={[0, 2, 3]}
                      pt={[0, '16px', 0]}
                      color="white"
                      fontSize={[10, 9, 10]}
                      fontWeight="bold"
                      lineHeight="1.03"
                    >
                      {index + 1}
                    </Text>
                    <Flex pl={[0, 3, 2]} flexDirection="column" flexWrap="wrap">
                      <Text
                        key="easyStepsDescriptionsTitle"
                        pt={[0, '25px', '15px']}
                        color="maize"
                        fontSize={[0, '15px', 3]}
                        fontWeight="bold"
                        lineHeight="0.94"
                        letterSpacing="0.94px"
                        textAlign="left"
                      >
                        {t(action.title)}
                      </Text>
                      <Hide mobile tablet>
                        <Text
                          key={'easyStepsDescriptionsSubtitle' + index}
                          color="white"
                          fontSize={[0, 1, 1]}
                          lineHeight="1.03"
                          textAlign="left"
                        >
                          {t(action.subtitle)}
                        </Text>
                      </Hide>
                    </Flex>
                  </Flex>
                ))}
            </Flex>
          </Box>
        </Container>
      </Hide>
    </>
  );
}
